








import { defineComponent, getCurrentInstance } from '@vue/composition-api'

import { TagWrapper } from '../../abstract/components/TagWrapper/TagWrapper.vue'

import { customHeadingProps, useCustomHeading } from './Heading.hooks'
import { HeadingProps } from './Heading.contracts'

/**
 * Component capable to render Icon component.
 *
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const Heading = defineComponent({
  name: 'Heading',
  components: { TagWrapper },
  props: customHeadingProps,

  setup (props: HeadingProps) {
    return useCustomHeading(props, getCurrentInstance())
  }
})
export default Heading
